import axios from "axios";

// api.js
const BASE_URL = 'https://api.mememall.tv'; // Replace with your API base URL
//const BASE_URL = 'http://localhost:8080'; // Replace with your API base URL
//
const token = localStorage.getItem('token');

export const fetchMenuData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-category`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const getTokenFromApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: 'ecom@template.com',
        password: 'ecom123'
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const jwtToken = data.jwtToken;

    // Store token in local storage for future use
    localStorage.setItem('token', jwtToken);

    return jwtToken;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const fetchSlider = async () => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-banner`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchProductsByMenu = async (menu, offset, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-products/${menu}/${offset}/${pageSize}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchProduct = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-product/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};


//LOGIN APIS
export const loginUser = async (email, password) => {
  try {
    const payload = {
      email,
      password
    };

    const response = await axios.post(`${BASE_URL}/ui-user-login`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.status === 200) {
      throw new Error('Network response was not ok');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const getUser = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-profile/${email}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const registerUser = async (email, password, name, referredBy) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-registration`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password, name, referredBy
      })
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const otpConfirmation = async (email, otp) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-otp-confirmation/${email}/${otp}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const resendOtp = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-resend-otp/${email}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchCollection = async (offset, pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-collections/${offset}/${pagesize}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const addCart = async (email, sku, qty) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        sku, qty
      })
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const removeItem = async (email, sku) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart/${email}/${sku}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await response.json();
    } else {
      return await response.text();  
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const clearCart = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart/${email}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }

    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const viewCart = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-view-cart/${email}`, {
      method: 'POST',

      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
// export const placeOrder = async (order, orderItems) => {
//   try {
//     const response = await fetch(`${BASE_URL}/ui-place-order`, {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         order, orderItems
//       })
//     });
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     return await response.json()
//   } catch (error) {
//     console.error('Error fetching menu data:', error);
//     throw error;
//   }
// };
export const placeOrder = async (order, orderItems) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-place-order`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        order, orderItems
      })
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    // Check if the response content type is JSON
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await response.json();
    } else {
      return await response.text();  // Handle text response
    }
  } catch (error) {
    console.error('Error placing order:', error);
    throw error;
  }
};
export const getOrders = async (email, offset, pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-orders/${offset}/${pagesize}/${email}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const getSinglrProduct = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-orders/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const getAddress = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-addresses/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const addNewAddress = async (editableAddress) => {
  console.log(editableAddress);
  try {
    const response = await fetch(`${BASE_URL}/ui-add-address`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, body: JSON.stringify(
        editableAddress
      )

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const deleteAddress = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-add-address/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const addWishList = async (userId, productId) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-add-wishlist`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        productId
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}

export const getWishList = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-wishlist/${email}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}
export const searchproduct = async (keyword) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-search-products/${keyword}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}
export const stripePay = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/stripe/pay`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          method: payload.method,
          currency: payload.currency,
          amnt: payload.amount
        })

  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json()
} catch (error) {
  console.error('Error fetching menu data:', error);
  throw error;
}
};
export const selectedFilterOptions = async (filterOption) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-filter-products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(filterOption)

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const updateGuestCart = async (uuid, username) => {
  try {
    const response = await fetch(`${BASE_URL}/update-cart/${uuid}/${username}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
  
}
export const getTracking = async (orderId) => {
  try {
    const response = await fetch(`${BASE_URL}/get-order-track/${orderId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}