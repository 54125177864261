// MememallFooter.js
import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="Mememall-footer">
      <div className="Mememall-footer-content">
        <div className="Mememall-footer-section">
          <h5>Get to Know Us</h5>
          <ul>
            <li>About Us</li>
            <li>Careers</li>
            <li>Press Releases</li>
            <li>Mememall Cares</li>
            <li>Gift a Smile</li>
          </ul>
        </div>
        <div className="Mememall-footer-section">
          <h5>Make Money with Us</h5>
          <ul>
            <li>Sell on Mememall</li>
            <li>Sell under Mememall Accelerator</li>
            <li>Become an Affiliate</li>
            <li>Fulfilment by Mememall</li>
            <li>Advertise Your Products</li>
            <li>Mememall Pay on Merchants</li>
          </ul>
        </div>
        <div className="Mememall-footer-section">
          <h5>Mememall Payment Products</h5>
          <ul>
            <li>Mememall Business Card</li>
            <li>Shop with Points</li>
            <li>Reload Your Balance</li>
            <li>Mememall Currency Converter</li>
          </ul>
        </div>
        <div className="Mememall-footer-section">
          <h5>Let Us Help You</h5>
          <ul>
            <li>Mememall and COVID-19</li>
            <li>Your Account</li>
            <li>Your Orders</li>
            <li>Shipping Rates & Policies</li>
            <li>Returns & Replacements</li>
            <li>Manage Your Content and Devices</li>
            <li>Mememall Assistant</li>
            <li>Help</li>
          </ul>
        </div>
      </div>
      <div className="Mememall-footer-bottom">
        <p>&copy; {new Date().getFullYear()} Mememall.tv, Inc. or its affiliates</p>
      </div>
    </footer>
  );
};

export default Footer;
