import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      p={4}
    >
      <ErrorOutlineIcon color="error" style={{ fontSize: 100 }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Payment Failed
      </Typography>
      <Typography variant="body1" gutterBottom>
        Unfortunately, your payment could not be processed. Please try again.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          width:'20%',
          backgroundColor: '#f0c14b',
          '&:hover': {
            backgroundColor: '#e68a00',
          },
        }}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default Failure;
