import React, { useState } from 'react';
import './Login.css'; // Import the CSS file
import { loginUser } from '../../util/Api';
import { getUser } from '../../util/Api';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../util/Store';

const Login = ({ registrationPressed }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegistrationcmd = () => {
    registrationPressed('pressed');
  };
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogin = async (event) => {
    event.preventDefault();

    // Reset previous errors
    setEmailError('');
    setPasswordError('');
    setErrorMessage('');

    // Validate email and password
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }

    // Proceed with login
    loginUser(email, password)
      .then(data => {
        if (data === 'User authentication success') {
          getUser(email).then(userData => {
            if (userData) {
              localStorage.setItem("userData", JSON.stringify(userData.userData))
              localStorage.setItem("profile", JSON.stringify(userData))

            }
            dispatch(login(userData))
          })
        }
      }).then(() => {
        navigate('/')
      })
      .catch(error => {
        // Handle login error
        console.error('Login failed:', error);
        setErrorMessage('Failed to login. Please try again.');
      });
  };

  // Function to validate email format
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="amazon-login-container">
      <div className="amazon-login-box">
        <h2>Sign in</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <span className="error-message">{emailError}</span>}
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <span className="error-message">{passwordError}</span>}
          </div>
          <button type="submit">Sign in</button>
          {errorMessage && <p className="global-error-message">{errorMessage}</p>}
          <p>By continuing, you agree to Meme Mall's Conditions of Use and Privacy Notice.</p>
        </form>
        <hr />
        <p>New to Amazon?</p>
        <Link to='/registration'><button onClick={() => handleRegistrationcmd()}>Create your account</button></Link>
      </div>
    </div>
  );
};

export default Login;
