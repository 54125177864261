import React, { useEffect, useState } from "react";
import "./SingleProductView.css";
import { addCart, addWishList } from "../../util/Api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import ProductDetailsTables from "../product/ProductDetailsTables";
import ProductList from "../product/ProductHomeScreen";
import lock from "../../assets/lock.png";
import CustomerReview from "../product/CustomerReview";
import NewReviewForm from "../product/NewReviewForm"; // Import NewReviewForm component
import { v4 as uuidv4 } from 'uuid';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch } from "react-redux";
import { cartItems } from "../../util/Store";

const SingleProductView = ({ product }) => {
  const [img, setSelectedImage] = useState("");
  const [price, setPrice] = useState(0);
  const [offer, setOffer] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [isSizeSelected, setIsSizeSelected] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [products, setProductData] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  let uuid = localStorage.getItem("uuid")
  if (!uuid || uuid == undefined) {
    uuid = uuidv4()
    localStorage.setItem("uuid", uuid)
  }
  const dispatch = useDispatch()
  const handleAddToWishlist = () => {
    addWishList(userData.email, product.id)
      .then((response) => {
        console.log("Item added to wishlist:", response);
      })
      .catch((error) => {
        console.error("Error adding item to wishlist:", error);
      });
    setIsAddedToWishlist(true);
  };

  const handleSizeSelection = (size) => {
    setSelectedSize(size);
    setIsSizeSelected(true);
    const selectedSizeInfo = product.sizes.find((s) => s.name === size);
    if (selectedSizeInfo && selectedSizeInfo.stocks === 0) {
      setIsOutOfStock(true);
    } else {
      setIsOutOfStock(false);
    }
  };
  const navigate = useNavigate()
  const handleAddToCart = () => {
    const existingCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    if (!isSizeSelected || !product.sizes || product.sizes.length === 0) {
      setSnackbar({ open: true, message: "Please select a valid size.", severity: 'warning' });
      return;
    }

    const selectedSizeInfo = product.sizes.find((s) => s.name === selectedSize.name);
    if (!selectedSizeInfo) {
      setSnackbar({ open: true, message: "Selected size information not found.", severity: 'warning' });
      return;
    }

    const existingCartItem = existingCartItems.find(
      (item) => item.sku === selectedSizeInfo.sku
    );

    if (existingCartItem) {
      setSnackbar({ open: true, message: "This item is already in your cart.", severity: 'info' });
      return;
    }

    const cartItem = {
      name: product.productName,
      quantity: 1,
      unitprice: selectedSizeInfo.price,
      color: product.color,
      imgUrl: product.images.join(","),
      sku: selectedSizeInfo.sku,
      offer: product.offer,
      description: product.description,
      subt: selectedSizeInfo.price - ((selectedSizeInfo.price / 100) * product.offer)
    };

    const updatedCartItems = [...existingCartItems, cartItem];
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    const id = userData ? userData.email : uuid;
    addCart(id, selectedSizeInfo.sku, 1)
      .then((response) => {
        setSnackbar({ open: true, message: "Item added to cart successfully.", severity: 'success' });
        dispatch(cartItems(true))
      })
      .catch((error) => {
        console.error("Error adding item to cart:", error);
        dispatch(cartItems(true))
        setSnackbar({ open: true, message: "Failed to add item to cart.", severity: 'error' });
      });
  };
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (product) {
      setSelectedImage(product.images ? product.images[0] : "");
      setSelectedSize(product.sizes ? product.sizes[0] : "");
      setPrice(product.sizes ? product.sizes[0].price : 0);
      setOffer(product.offer ? product.offer : 0);
      setIsSizeSelected(true);
      if (userData) {
        setProductData(JSON.parse(localStorage.getItem('profile')).wishList)
      }
      if (products) {
        let boolean = products.find(p => p.id === product.id)
        setIsAddedToWishlist(boolean ? true : false);

      }
    }
  }, [product]);

  useEffect(() => {
    const discountedPrice = price - (price * offer) / 100;
    setDiscountedPrice(discountedPrice);
  }, [price, offer]);

  const handleSelectImage = (image) => {
    setSelectedImage(image);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleBuyNow = () => {
    navigate("/payment");
  };

  if (!product || !product.images || !product.sizes) {
    return (
      <div>
        <Loader />
      </div>
    );
  }


  return (
    <div className="s-container">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="row">
            <div className="col-2">
              <div className="row vertical-thumbnail">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className="thumbnail-image"
                    onClick={() => handleSelectImage(image)}
                    alt={`Thumbnail ${index}`}
                  />
                ))}
              </div>
            </div>
            <div className="col-9">
              <div className="single-product-image">
                <img src={img} alt={product.productName} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <h2>{product.productName}</h2>
          <p>{product.description}</p>
          <hr />
          <p className="offer-txt">Limited time deal</p>
          <p>
            <span className="offer-val">-{product.offer}%</span>
            <span className="a-price-symbol">$</span>
            <span className="a-price-whole">{discountedPrice}</span>
            <br />
            <span className="og-text">Original price:</span>{" "}
            <span className="og-price">${product.sizes[0].price}</span>
          </p>
          <p>Inclusive of all taxes</p>
          <hr />
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="card">
            <p>${discountedPrice}</p>
            <div className="product-sizes">
              {product.sizes.map((size, index) => (
                <div
                  key={index}
                  className={`size-box ${selectedSize.name === size.name ? "selected" : ""
                    }`}
                  onClick={() => handleSizeSelection(size)}
                >
                  <span className="size">{size.name}</span>
                </div>
              ))}
            </div>
            <p>Free Delivery</p>
            <p className="stock">
              {isOutOfStock ? "Out of Stock" : "In Stock"}
            </p>

            <span className="size-span">
              <span>Ships from </span>
              <span>Meme Mall </span>
            </span>
            <span className="size-span">
              <span>Sold by </span>
              <span>Meme Mall </span>
            </span>
            <div className="buttons-container">
              <button
                className="add-to-cart-btn"
                onClick={() => handleAddToCart()}
              >
                {isOutOfStock ? "Out of Stock" : "Add to Cart"}
              </button>
              <button className="buy-now-btn" onClick={handleBuyNow}>
                Buy Now
              </button>
            </div>
            <div class="secure-transaction">
              <img className="lock-icon" src={lock} />
              <span class="secure-text">Secure Transaction</span>
            </div>
            <hr />
            <div className="buttons-container">
              {userData ? (
                <button
                  className={`add-to-wishlist ${isAddedToWishlist ? "added" : ""
                    }`}
                  onClick={handleAddToWishlist}
                  disabled={isAddedToWishlist}
                >
                  {isAddedToWishlist ? "Remove from Wishlist" : "Add to Wishlist"}
                </button>
              ) : (
                <button className="add-to-wishlist" disabled>
                  Please log in to add to Wishlist
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5>Similar products...</h5>

      <ProductList collect={product.collectionNames.split(',')[0]} page={0} pagesize={5} />
      <h5>Most searched products...</h5>

      <ProductList collect={product.collectionNames.split(',')[1]} page={0} pagesize={5} />
      {product.productInfo && (
        <><h5 className="product-info">Product information</h5><ProductDetailsTables
          technicalDetails={product.productInfo.Technical}
          additionalInfo={product.productInfo.Additional} /></>
      )

      }

      <ProductList collect={"Sponsored"} page={0} pagesize={5} />
      <div className="review">
        <h5>Customer Reviews</h5>
        <div className="reviews-container">
          {userData ? (
            // If user is logged in and there are reviews available, display them
            product.reviews && product.reviews.length > 0 ? (
              product.reviews.map((review) => (
                <CustomerReview key={review.id} review={review} userData={userData} />
              ))
            ) : (
              // If user is logged in but there are no reviews, display a message
              <p>Be the first reviewer!</p>
            )
          ) : (
            // If user is not logged in, display a message to prompt login
            <p>Please login to review.</p>
          )}
          {userData && <NewReviewForm productId={product.id} />} {/* Display the form for logged-in users */}
        </div>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default SingleProductView;
