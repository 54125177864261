import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  IconButton,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import { addNewAddress, deleteAddress, viewCart } from "../../util/Api";
import { useNavigate } from "react-router-dom";
import "./AddressList.css";
import { addressUpdate } from "../../util/Store";
import { useDispatch } from "react-redux";
import AlertDialog from "../constants/Dialog";

const initialValue = {
  name: "",
  flatNo: "",
  address1: "",
  address2: "",
  country: "",
  zip: "",
  email: "",
  phone: ""
}

const AddressList = ({
  addresses,
  selectedAddress,
  onSelectAddress,
  userId,
  setAddress
}) => {
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [addAddressDisabled, setAddAddressDisabled] = useState(false);
  const [addressValue, setAddressValue] = useState(initialValue)
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    if (!addresses || addresses.length === 0) {
      setShowAddAddressForm(true);
    }
  }, [addresses]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      viewCart(userData.email)
        .then((data) => {
          setCartItems(data);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    } else {
      const cart = localStorage.getItem("cartItems");
      setCartItems(JSON.parse(cart));
    }
  }, []);
  const dispatch = useDispatch()

  const validateFields = () => {
    let newErrors = {};
    if (!userId) {
      const requiredFields = [
        "name",
        "flatNo",
        "address1",
        "address2",
        "country",
        "zip",
        "email",
        "phone",
      ];
      requiredFields.forEach((field) => {
        if (!addressValue[field] || addressValue[field].trim() === "") {
          newErrors[field] = "This field is required";
        }
      });
    }
    if (userId) {
      const requiredFields = [
        "name",
        "flatNo",
        "address1",
        "address2",
        "country",
        "zip",
      ];
      requiredFields.forEach((field) => {
        if (!addressValue[field] || addressValue[field].trim() === "") {
          newErrors[field] = "This field is required";
        }
      });
    }

    // Validate email format
    if (addressValue.email && !isValidEmail(addressValue.email)) {
      newErrors["email"] = "Please enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleProceedToPay = () => {
    if (validateFields()) {
      cartItems.order = addressValue;
      setCartItems(cartItems);
      localStorage.setItem("confirmed-cart", JSON.stringify(cartItems));
      navigate("/confirmation");
    }
  };

  const handleProceedToPayGuest = () => {
    if (validateFields()) {
      const order = {
        name: addressValue.name,
        flatNo: addressValue.flatNo,
        address1: addressValue.address1,
        address2: addressValue.address2,
        zip: addressValue.zip,
        userId: addressValue.userId,
        email: addressValue.email,
        phone: addressValue.phone,
        country: addressValue.country,
      };
      localStorage.setItem("confirmed-cart", JSON.stringify({ orderItems: cartItems, order: order }));
      navigate("/confirmation");
    }
  };

  const handleSelectAddress = (address) => {
    const order = {
      name: address.name,
      flatNo: address.flatNo,
      address1: address.address1,
      address2: address.address2,
      zip: address.zip,
      userId: address.userId,
      email: address.email,
      phone: address.phone,
      country: address.country,
    };
    setAddressValue(order)
    cartItems.order = order;
    setCartItems(cartItems);
    onSelectAddress(address.id);
    setAddAddressDisabled(true);
  };

  const handleEditAddress = (address) => {
    setAddressValue(address);
  };
  const handleDeleteAddress = async (addressId) => {
    setAddressToDelete(addressId);
    setOpen(true);
  };

  const handleConfirmDelete = async (confirmed) => {
    if (confirmed && addressToDelete) {
      try {
        await deleteAddress(addressToDelete).then(() => {
          setAddress((prevAddresses) =>
            prevAddresses.filter((address) => address.id !== addressToDelete)
          );
          setAddressToDelete(null);
          dispatch(addressUpdate(true));
        });
      } catch (error) {
        dispatch(addressUpdate(true));
        console.error("Error deleting address:", error);
      }
    }
    setOpen(false);
  };

  const handleChange = (e, field) => {
    setAddressValue({
      ...addressValue,
      [field]: e.target.value,
    })
    if (e.target.value.trim() === "") {
      setErrors({
        ...errors,
        [field]: "This field is required",
      });
    } else {
      const newErrors = { ...errors };
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleAddAddress = () => {
    setShowAddAddressForm(true);
  };

  const handleCancelAddAddress = () => {
    setShowAddAddressForm(false);
    setAddAddressDisabled(false);
    setErrors({});
  };

  const handleSaveNewAddress = async () => {
    if (validateFields()) {
      try {
        addressValue.userId = userId;
        await addNewAddress({ ...addressValue }).then(() => {
          dispatch(addressUpdate(true))
        });
        setShowAddAddressForm(false);
        setAddressValue(initialValue)
        setErrors({});
      } catch (error) {
        console.error("Error saving new address:", error);
      }
    }
  };

  return (
    <Box className="address-list-check">
      <Paper elevation={3} className="address-list-container">
        <Typography variant="h6" gutterBottom>
          Select a delivery address
        </Typography>
        <Grid container spacing={3}>
          {addresses &&
            addresses.map((address) => (
              <Grid item xs={12} md={6} key={address.id}>
                <Paper elevation={2} className="address-item">
                  <div className="address-actions">
                    <IconButton onClick={() => handleEditAddress(address)}>
                      <FaEdit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteAddress(address.id)}>
                      <FaTrash />
                    </IconButton>
                  </div>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="address"
                      name="address"
                      value={selectedAddress}
                      onChange={() => handleSelectAddress(address)}
                    >
                      <FormControlLabel
                        value={address.id}
                        control={<Radio />}
                        label={
                          <Typography variant="body1">
                            <strong>{address.name}</strong>
                            <br />
                            {`${address.flatNo}, ${address.address1}, ${address.address2}, ${address.country}, ${address.zip}`}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>
            ))}
        </Grid>

        {showAddAddressForm && (
          <Box className="add-address-form" component={Paper} elevation={2} p={3} mt={3}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={6} md={6} >
                <TextField
                  label="Name *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "name")}
                  error={!!errors.name}
                  helperText={errors.name}
                  margin="normal"
                  value={addressValue.name}
                />
                {!userId && (
                  <>
                    <TextField
                      label="Email *"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleChange(e, "email")}
                      error={!!errors.email}
                      helperText={errors.email}
                      margin="normal"
                      value={addressValue.email}
                    />
                    <TextField
                      label="Phone *"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleChange(e, "phone")}
                      error={!!errors.phone}
                      helperText={errors.phone}
                      margin="normal"
                      value={addressValue.phone}
                    />
                  </>
                )}
                <TextField
                  label="Flat No *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "flatNo")}
                  error={!!errors.flatNo}
                  helperText={errors.flatNo}
                  margin="normal"
                  value={addressValue.flatNo}
                />
                {userId && <TextField
                  label="Country *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "country")}
                  error={!!errors.country}
                  helperText={errors.country}
                  margin="normal"
                  value={addressValue.country}
                />}
              </Grid>
              <Grid item xs={2} sm={6} md={6}>
                <TextField
                  label="Address Line 1 *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "address1")}
                  error={!!errors.address1}
                  helperText={errors.address1}
                  margin="normal"
                  value={addressValue.address1}
                />
                <TextField
                  label="Address Line 2 *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "address2")}
                  error={!!errors.address2}
                  helperText={errors.address2}
                  margin="normal"
                  value={addressValue.address2}
                />
                {!userId && <TextField
                  label="Country *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "country")}
                  error={!!errors.country}
                  helperText={errors.country}
                  margin="normal"
                />}
                <TextField
                  label="Zip *"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e, "zip")}
                  error={!!errors.zip}
                  helperText={errors.zip}
                  margin="normal"
                  value={addressValue.zip}
                />
              </Grid>
            </Grid>
            {userId && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#f0c14b", width: "15%" }}
                onClick={handleSaveNewAddress}
                fullWidth
              >
                Save Address
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelAddAddress}
                fullWidth
                style={{ marginTop: 10, width: "15%" }}
              >
                Cancel
              </Button>

            </Box>}
          </Box>
        )}

        {!showAddAddressForm && (
          <Box className="add-address-button" mt={3}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f0c14b" }}
              onClick={handleAddAddress}
              disabled={addAddressDisabled}
              startIcon={<FaPlus />}
              fullWidth
            >
              Add Address
            </Button>
          </Box>
        )}

        {cartItems && (
          <Box className="proceed-to-pay-button" mt={3}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f0c14b", width: '15%' }}
              onClick={userId ? handleProceedToPay : handleProceedToPayGuest}
              fullWidth
            >
              Confirm Checkout
            </Button>
          </Box>
        )}
      </Paper>
      <AlertDialog open={open} setOpen={setOpen} onConfirm={handleConfirmDelete} content={' Are You sure you want to delete the address?'} />
    </Box>
  );
};

export default AddressList;
