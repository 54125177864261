import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import Loader from '../loader/Loader';
import { addCart, viewCart, removeItem } from "../../util/Api";
import logo from '../../assets/logo.png';
import { cartItems } from "../../util/Store";
import AlertDialog from "../constants/Dialog";

const ViewCartPage = () => {
  const [cartItem, setCartItems] = useState([]);
  const [res, setRes] = useState(null);
  const [deleteCartItem, setDeleteCartItem] = useState(null)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const uuid = localStorage.getItem("uuid");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const id = userData ? userData.email : uuid;
    viewCart(id)
      .then((data) => {
        setCartItems(data.orderItems);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        setIsLoading(false);
      });
  }, [res]);

  const handleQuantityChange = (e, item) => {
    const newQuantity = parseInt(e.target.value);
    const updatedCartItems = cartItem.map((cartItem) => {
      if (cartItem.sku === item.sku) {
        return { ...cartItem, quantity: newQuantity };
      } else {
        return cartItem;
      }
    });
    setCartItems(updatedCartItems);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const id = userData ? userData.email : uuid;
    addCart(id, item.sku, newQuantity)
      .then((response) => {
        setRes(response);
        dispatch(cartItems(true))
      })
      .catch((error) => {
        console.error("Error updating item quantity:", error);
      });
    localStorage.removeItem("confirmed-cart");
  };

  const handleRemoveItemFromCart = (item) => {
    setOpen(true)
    setDeleteCartItem(item)

  };
  const handleConfirmClick = (confirmation) => {
    if (confirmation) {
      const updatedCartItems = cartItem.filter(
        (cartItem) => cartItem.sku !== deleteCartItem.sku
      );
      setCartItems(updatedCartItems);
      if (updatedCartItems.length === 0) {
        setCartItems([]);
      }
      const userData = JSON.parse(localStorage.getItem("userData"));
      const id = userData ? userData.email : uuid;
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      removeItem(id, deleteCartItem.sku)
        .then((response) => {
          dispatch(cartItems(true))
          setOpen(false)
        }).then(()=>{
          dispatch(cartItems(false))
        })
        .catch((error) => {
          dispatch(cartItems(true))
          setOpen(false)
          console.error("Error removing item from cart:", error);
        });
    }
  }

  const handleProceedToCheckout = () => {
    navigate("/address");
  };

  return (
    <Container sx={{ pt: 4, "&.MuiContainer-root ": { maxWidth: "100%" } }}>
      {isLoading ? (
        <Loader />
      ) : cartItem.length === 0 ? (
        <Box textAlign="center" my={16}>
          <img src={logo} alt="No products" style={{ width: 100, height: 100 }} />
          <Typography variant="h6" mt={2}>Hey, It feels so light!</Typography>
          <Typography color="textSecondary">There is nothing in your bag. Let's add some items.</Typography>
          <Button variant="outlined" color="secondary" sx={{ mt: 2 }}>ADD ITEMS FROM WISHLIST</Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" sx={{ padding: "90px 16px" }}>
          <Box flex={1} mr={2}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Your Cart</Typography>
              {cartItem.map((item) => (
                <Box key={item.id} mb={2} position="relative">
                  <Box display="flex" alignItems="center">
                    <img
                      src={item.imgUrl.split(",")[0]}
                      alt={item.name}
                      style={{ width: 100, height: 100, objectFit: 'cover', marginRight: 16 }}
                    />
                    <Box flex="1">
                      <Typography variant="subtitle1">{item.name}</Typography>
                      <Typography variant="body2" color="textSecondary">Size: {item.size}</Typography>
                      <Typography variant="body2" color="textSecondary">Color: {item.color}</Typography>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                        <Select
                          value={item.quantity}
                          onChange={(e) => handleQuantityChange(e, item)}
                          sx={{ minWidth: 60 }}
                        >
                          {[1, 2, 3, 4, 5].map((quantity) => (
                            <MenuItem key={quantity} value={quantity}>
                              {quantity}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box display="flex" mt={1}>
                        <DeleteIcon onClick={() => handleRemoveItemFromCart(item)} sx={{ cursor: 'pointer' }} />
                        <ShareIcon sx={{ cursor: 'pointer' }} />
                      </Box>
                    </Box>
                    <Typography variant="subtitle1" sx={{ mr: 2 }}>${item.subt}</Typography>
                  </Box>
                  <Box position="absolute" top={8} right={8} display="flex" alignItems="center">
                    <BookmarkIcon />
                    <Typography variant="body2" color="textSecondary" ml={1}>Save for Later</Typography>
                  </Box>
                  <Divider sx={{ py: '10px', borderColor: '#000' }} />

                </Box>
              ))}
              <Typography variant="body2" color="textSecondary" mt={2}>
                The price and availability of items at Mememall.tv are subject to change. The shopping cart is a temporary place to store a list of your items and reflects each item's most recent price. Do you have a promotional code? We'll ask you to enter your claim code when it's time to pay.
              </Typography>
            </Paper>
          </Box>
          <Box width={300}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Cart Summary</Typography>
              <Typography>Total Items: {cartItem.length}</Typography>
              <Typography>Total Price: ${cartItem.reduce((total, item) => total + parseFloat(item.subt), 0).toFixed(2)}</Typography>
              <Typography variant="caption" display="block" mt={1}>(Inclusive of all taxes)</Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: '#f0c14b',
                  color: '#111',
                  '&:hover': {
                    backgroundColor: '#ddb347',
                  }
                }}
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </Button>
            </Paper>
          </Box>
        </Box>
      )}
      <AlertDialog content={'Are you sure you want to delete the product?'} onConfirm={handleConfirmClick} setOpen={setOpen} open={open} />
    </Container>
  );
};

export default ViewCartPage;
